#Preloader {
    --bg: var(--black);
    // --bg: var(--primary-bg);
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    background-color: var(--bg);
}
