#Cta {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-xs);
    --font-size: var(--font-size-base);
    
    @media (min-width: $smartphone) {
        display: none;
    }
}

#Cta {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    right: 0;
    bottom: 0;
    padding: 0 var(--padding-h);

    color: var(--color);
    background: var(--bg);

    @include font-sans();
    font-size: var(--font-size);
    
    > div {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: var(--padding-v) 0;
        border-top: 1px solid currentColor;
    }

    a {
        @include basic-a();
        color: inherit;
    }
}
