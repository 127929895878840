@use "sass:math";

#CMP {
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --font-size: var(--font-size-large);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --padding-info: var(--padding-m);
    --border-size: #{rem(2)};
    
    --gap-general: #{rem(100)};
    --gap-btns: var(--padding-xs);
    
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    @media (max-width: $smartphone) {
        --padding-info: var(--padding-xs);
        --border-size: 1px;
    
        --padding-h: var(--padding-xs);
        --padding-v: var(--padding-s);

        > div {
            overflow-y: scroll;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }
    }
}

#CMP {
    @include z-index($z-index-windows);
    @include font-sans();

    position: fixed;
    left: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
    max-height: 100vh;
    
    background-color: var(--bg);
    color: var(--color);
    font-size: var(--font-size);

    padding: 0 var(--padding-h);
    
    > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--color);
    }
    
    .content {
        flex: 1 0 40%;
        display: block;
        position: relative;

        padding: var(--padding-v) 0;
    }

    > div > .btns {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-btns);
        padding: var(--padding-v) 0;
    }

    a {
        @include basic-a();
        color: var(--color);
        text-decoration: underline;
    }
}

#CMP > div > .information {
    flex: 1 0 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
        
    &[aria-expanded='false'] {
        display: none;
    }
}

#CMP > div > .information > .information__type {
   color: var(--dark-green);
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: var(--padding-info) 0;
   
   &:not(:last-of-type) {
    border-top: var(--border-size) solid var(--color);
   }
      
   > .title {
        &:empty {
            display: none;
            visibility: hidden;
            pointer-events: none;
        }

        font-size: 1em;
        font-weight: bold;
        white-space: normal;
   }

   > .text {
        flex: 1;
        width: 100;
        white-space: normal;
        max-width: #{rem(1300)};
   }

   > .checks {
        flex: 0 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--grey);
   }

   > .checks  > .check {
        --width: #{rem(65)};
        --height: #{rem(32)};
        --radius: #{rem(16)};
        --thumb-width: #{rem(24)};
        --thumb-offset: calc((var(--height) - var(--thumb-width))/2);
        --position: var(--thumb-offset);
        --opacity: 1;

        &.--disabled,
        &.--active {
            --position: calc(var(--width) - var(--thumb-width) - var(--thumb-offset));
        }

        &.--disabled {
            --opacity: .4;
            pointer-events: none;
        }

        position: relative;
        width: var(--width);
        height: var(--height);
        border-radius: var(--radius);
        
        &::before {
            content: '';
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            border: var(--border-size) solid var(--color);
            border-radius: var(--radius); 
            background-color: var(--bg);
        }

        &::after {
            content: '';
            position: absolute;
            top: var(--thumb-offset);
            left: 0;
            width: var(--thumb-width);
            height: var(--thumb-width);
            border-radius: var(--height); 
            background-color: var(--color);
            transform: translateX(var(--position));
            transition: transform .1s ease-out;
        }
   }
}

#CMP > div > .information > .information__controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 0 var(--padding-v);
}

@media (max-width: $smartphone) {
    #CMP {
        .content {
            padding: var(--padding-v) 0;
            flex: 1 0 100%;
        }

        > div > .btns {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 0 0 var(--padding-v);
            flex: 1 0 100%;

            > .button:first-child {
                grid-column: 1 / 3;
            }
        }
    }

    #CMP > div > .information > .information__type {
        > .checks {
            align-items: flex-start;
            justify-content: flex-start;
            width: #{rem(38)};
        }

        > .text {
            padding-right: var(--padding-info);
        }

        > .checks  > .check {
            --width: #{rem(38)};
            --height: #{rem(22)};
            --radius: #{rem(11)};
            --thumb-width: #{rem(16)};
        }
    }
}