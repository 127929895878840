#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-m);
    --font-size: var(--font-size-base);
    --size-logo: #{rem(84)};
    --height-logo: #{rem(31)};
    
    @media (max-width: $smartphone) {
        --font-size-logo: #{rem(19)};
        --padding-h: var(--padding-xs);

        .button,
        .link-underline {
            --font-size: #{rem(13)};
        }
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: 0 var(--padding-h);

    color: var(--color);
    background-color: var(--bg);

    @include font-sans();
    font-size: var(--font-size);
    
    > div {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--padding-v) 0;
        border-bottom: 1px solid var(--color);
    }

    .logo {
        svg {
            display: block;
            width: var(--size-logo);
            height: var(--height-logo);
            fill: var(--color);
        }
    }

    a {
        @include basic-a();
        color: inherit;
    }

    .--active {
        pointer-events: none;
    }

    nav {
        display: flex;
        gap: var(--padding-xxs);
        
        .langs {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--padding-xxxs);
        }
    
        .types {
            display: flex;
            gap: var(--padding-xxs);
        }
    }

    @media (max-width: $smartphone) {
        .left,
        .center {
            position: absolute;
            inset: 0 0 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(0);
            transition: transform .2s ease-out;
        }

        .left {
            //.--up & {
                transform: translateY(-100%);
            //}
        }
        
        .center {
            /*.--down & {
                transform: translateY(-100%);
            }*/
        }

        .right {
            display: none;
        }

        > div {
            justify-content: center;
        }
    }

    @media (min-width: $smartphone) {
        > div {
            .center {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: var(--padding-xs);
                position: relative;
                
                .pages,
                .langs,
                .types {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .types {
                    gap: var(--padding-xs);

                    > div {
                        display: flex;

                        &:first-child {
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }
}
