.block-workshop-detail {
    --font-size-big: var(--font-size-large);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-m);
    --padding-h: 0;
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.block-workshop-detail {
    @include font-sans();
    font-size: var(--font-size);
    padding-top: var(--header-height);

    > div {
        padding: var(--padding-v) var(--padding-h);
    }
    
    .title {
        @include font-sans-bold();
        font-size: var(--font-size-big);
    }

    .text {
        @include font-serif();

        p:not(:last-child) {
            margin-bottom: var(--padding-xs);
        }
    }

    .logo {
        figure {
            border-bottom: 1px solid var(--primary-color);
            border-top: 1px solid var(--primary-color);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .column {
        ul > li {
            --origin: left;
            --size: 1;
            
            @include isCursor() {
                &:hover {
                    --size: 0;
                    --origin: right;
                }
            }

            position: relative;

            &:first-child {
                border-top: 1px solid currentColor;
            }
            
            a {
                @include font-sans(1);
                @include basic-a();
                color: var(--primary-color);
                display: flex;
                justify-content: space-between;
                align-content: center;
                padding: var(--padding-xxs) 0;

                &:not(.link-arrow) span:last-child {
                    flex-grow: 1;
                    text-align: right;
                }

                &::after {
                    background-color: var(--primary-color);
    
                    content: "";
                    position: absolute;
                    inset: auto 0 0 0;
                    
                    display: block;
                    height: 1px;
                    
                    scale: var(--size) 1;
                    // transition: scale 0.3s ease;
                    transform-origin: var(--origin);
                }

                &[aria-current="page"] {
                    --size: 1;
                    font-weight: 700;
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        padding-top: calc(var(--header-height) + var(--padding-m));

        > div {
            display: flex;
            flex-direction: column-reverse;
        }

        // .logo {
            // figure {
                // border-top: 0;
            //     padding: var(--padding-xs) 0;
            // }
        // }

        .column {
            ul > li {
                min-width: 100%;

                a {
                    border-bottom: 1px solid var(--primary-color);

                    &::after {
                        display: none;
                    }
                }
            }
        }

        .row .column {
            &:not(:last-child) {
                margin-bottom: var(--padding-m);
            } 
        } 

        // > div > .column:last-child {
        //     padding-bottom: var(--padding-m);
        //     border-bottom: 1px solid currentColor;
        // } 

        > div > .column:first-child {
            margin-top: var(--padding-l);
            // border-bottom: 1px solid currentColor;
            
            .logo {
                margin-bottom: var(--padding-l);
            }
        } 

        .title {
            margin-bottom: var(--padding-xs);
            
            &:not(:first-child) {
                margin-top: var(--padding-m);
            }
        }
    }

    @media (min-width: $smartphone) {
        margin-bottom: var(--padding-l);

        > div {
            display: flex;

            > .column {
                width: 50%;
                
                &:last-child {            
                    padding: 0 var(--padding-m);     
                }
            }
        }

        .text {
            p:not(:last-child) {
                margin-bottom: rem(18);
            }
        }

        .logo {
            padding: 0 var(--padding-m);
            margin-bottom: var(--padding-m);
        }
        
        .column {
            position: relative;

            &:first-child {
                display: flex;
                flex-direction: column;

                .row {
                    flex-grow: 1;
                }

                &::after {
                    content: '';
                    height: 100%;
                    width: 1px;
                    background-color: var(--primary-color);
                    right: 0;
                    top: 0;
                    position: absolute;
                }
            }
        }

        .row {
            display: grid;
            grid-template-columns: var(--col1) var(--col2);

            > .column {         
                padding: 0 var(--padding-m);                
    
                &:first-child {
                    grid-column: span 1;
                }
    
                &:last-child {
                    grid-column: span 1;
                }
            }
        }
        
        .title {
            margin-bottom: var(--padding-m);
        }
    }
}
