#Footer {
    --font-size: var(--font-size-small);
    --padding-v: var(--padding-m);
    --padding-v-inner: var(--padding-s);
    --padding-h: var(--padding-m);

    --bizkaia-w: #{rem(100)};
    --bizkaia-h: #{rem(32)};
    
    @media (max-width: $smartphone) {
        --bizkaia: #{rem(90)};
        --padding-v: var(--header-height);
        --padding-v-inner: var(--padding-s);
        --padding-h: var(--padding-xs);
    }
}

#Footer {
    @include font-sans();

    font-size: var(--font-size);
    position: relative;
    padding: 0 var(--padding-h) var(--padding-v);
    background-color: var(--primary-bg);

    > div:first-child {
        border-top: 1px solid var(--primary-color);
        border-bottom: 1px solid var(--primary-color);
        padding: var(--padding-v-inner) 0;
    }
    
    > div:last-child {
        padding: var(--padding-v-inner) 0;
        
        svg {
            width: var(--bizkaia-w);
            height: var(--bizkaia-h);
            fill: var(--primary-color);
        }
    }

    a {
        @include basic-a();
        color: var(--primary-color);
    }

    .copyright {
        @include font-serif();

        // margin-bottom: rem(2);

        strong {
            @include font-sans-bold();
            font-size: var(--font-size-base);
        }
    }

    @media (max-width: $smartphone) {
        margin-top: var(--padding-v-inner);

        .copyright {
            margin-bottom: rem(2);
        }

        > div:first-child {
            > div:last-child {
                margin-top: var(--padding-v-inner);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        > div:last-child {
            .legales {
                margin-top: 1em;
            }
        }

    }
    
    @media (min-width: $smartphone) {
        > div:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div:last-child {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }

        > div:last-child {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 0;
        }
    }

    .footer-bottom {
        .legales {
            font-size: .7rem;
            a {
                margin-left: 1em;
            }
        }
    }
}
