@use "sass:math";

.block-accordions {
    --font-size-title: var(--font-size-base);
    --font-size-body: var(--font-size-base);

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-base);
        --font-size-body: var(--font-size-xsmall);
    }
}

.block-accordions {
    @include font-sans();

    list-style: none;
    max-width: var(--max-width);
    margin: 0 auto;

    // > li {
    //     border-bottom: 1px solid var(--primary-color);

    //     &:first-child {
    //         border-top: 1px solid currentColor;
    //     }
    // }

    //ACORDEON
    article {
        --duration: 0.4s;
        --ease: var(--ease-in-out-quad);

        // border-bottom: 1px solid var(--primary-color);

        // &:first-child {
        //     border-top: 1px solid currentColor;
        // }
    }

    article > button {
        // --origin: left;
        // --size: 1;

        @include isCursor() {
            &:hover {
                --size: 0;
                --origin: right;
                // color: var(--primary-bg);
                // background: var(--primary-color);
                // border-bottom-color: transparent;
            }
        }

        @include font-sans(1);
        @include basic-a();
        background-color: transparent;
        color: var(--primary-color);
        position: relative;
        width: 100%;
        padding: var(--padding-xxs) 0;
        font-size: var(--font-size-title);
        text-align: left;
        padding-left: var(--padding-xs);

        display: flex;
        align-items: center;
        justify-content: space-between;

        &::before {
            @include font-sans-bold(.8);
            content: '+';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate3d(0, -50%, 0);
        }

        &::after {
            background-color: var(--primary-color);

            content: "";
            position: absolute;
            // inset: auto 0 0 0;

            left: 0;
            bottom: 1px;
            right: 0;
            
            display: block;
            height: 1px;
            
            scale: var(--size) 1;
            // transition: scale .4s ease;
            transform-origin: var(--origin);
        }
    }

    article[aria-expanded='false'] {
        visibility: visible;
        pointer-events: initial;
    }
    
    article[aria-expanded='true'] {
        border-bottom: 1px solid var(--primary-color);
        
        > button {
            @include font-sans-bold(1);

            --size: 1;

            // color: var(--primary-bg);
            // background: var(--primary-color);

            &::before {
                content: '-';
            }
        }

    }

    article > div {
        @include font-serif();
        padding: var(--padding-m) 0;

        p:not(:last-child) {
            margin-bottom: var(--padding-xs);
        }
    }
    
    @media (min-width: $smartphone) {
        article > div {
            p:not(:last-child) {
                margin-bottom: rem(18);
            }
        }
    }
}
