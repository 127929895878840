.notfound {
    --font-size-big: var(--font-size-xxlarge);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.notfound {
    @include font-sans-bold();
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    color: var(--primary-color);
    
    h1 {
        @include font-display();
        font-size: var(--font-size-big);
        margin-bottom: var(--padding-xxl);
    }
    
    a {
        @include font-sans-bold();
    }

    @media (max-width: $smartphone) {
        h1 {
            margin-bottom: var(--padding-xl);
        }
    }
}
