.block-about {
    --font-size-big: var(--font-size-large);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-m);
    --padding-h: 0;
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
    }
}

.block-about {
    @include font-sans();
    background-color: var(--primary-bg);
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);

    .title {
        @include font-sans-bold();
        font-size: var(--font-size-big);
    }

    .polaroids > li:not(:last-child) figure {
        margin-bottom: var(--padding-xs);
    }

    .gallery li:not(:last-child) figure {
        margin-bottom: var(--padding-s);
    }

    .column {
        &:last-child {
            ul > li:first-child {
                border-top: 1px solid currentColor;
            }
        }

        ul > li {
            --origin: left;
            --size: 1;
            
            @include isCursor() {
                &:hover {
                    a {
                        border-bottom-color: transparent;;
                    }
                }
            }

            position: relative;
            
            a {
                @include font-sans(1);
                @include basic-a();
                color: var(--primary-color);
                display: flex;
                justify-content: space-between;
                align-content: center;
                padding: var(--padding-xxs) 0;

                border-bottom: 1px solid var(--primary-color);
            }

            .link-arrow {
                flex-direction: row-reverse;
            }
        }

        p {
            a {
                @include font-sans(1);
                @include basic-a();
                color: var(--primary-color);
            }
        }
    }

    @media (max-width: $smartphone) {
        > div {
            display: flex;
            flex-direction: column;
        }

        .column {
            &:first-child {
                order: 1;
                display: flex;
                flex-direction: column-reverse;
                margin-bottom: var(--padding-m);

                .polaroids {
                    margin-bottom: var(--padding-xs);
                }
            } 

            > &:last-child {
                order: 2;
                margin-bottom: var(--padding-m);
            } 

            > &:nth-child(2) {
                order: 3;
            } 

            ul > li {
                a {
                    border-bottom: 1px solid var(--primary-color);

                    &::after {
                        display: none;
                    }
                }
            }
        } 

        .title {
            margin-bottom: var(--padding-xs);
            
            &:not(:first-child) {
                margin-top: var(--padding-m);
            }
        }
    }

    @media (max-width: $smartphone) {
        padding-bottom: 0;
    }

    @media (min-width: $smartphone) {
        > div {
            display: grid;
            grid-template-columns: var(--col1) var(--col3) var(--col4) var(--col2);
        }
        
        .column {
            // padding: 0 var(--padding-m) var(--padding-xl);
            padding: 0 var(--padding-m);
            grid-column: span 1;
            position: relative;
            
            &:nth-child(2) {
                grid-column: span 2;
            } 
    
            &:not(:last-child) {
                &::after {
                    content: '';
                    height: 100%;
                    width: 1px;
                    background-color: var(--primary-color);
                    right: 0;
                    top: 0;
                    position: absolute;
                }
            }

            .gallery > li:not(:last-child) figure {
                margin-bottom: var(--padding-m);
            }
        }

        .text {
            margin-bottom: var(--padding-xs);

            p:not(:last-child) {
                margin-bottom: rem(18);
            }
        }
        
        .polaroids > li:not(:last-child) figure {
            margin-bottom: rem(18);
        }
    
        .title {
            margin-bottom: var(--padding-m);
            
            &:not(:first-child) {
                margin-top: var(--padding-m);
            }
        }
    }
}
