@use "sass:math";

:root {
    --font-sans: 'TWKEverett', sans-serif;
    --font-display: 'HelveticaNeue-Condensed', sans-serif;
    --font-serif: 'TimesNR', serif;

    --font-size-xxlarge: #{math.div(90px, 16px) * 1rem};
    --font-size-large: #{math.div(30px, 16px) * 1rem};
    --font-size-medium: #{math.div(17px, 16px) * 1rem};
    --font-size-base: #{math.div(15px, 16px) * 1rem};

    --line-height-sans: 1.26;
    --line-height-serif: 1.117;
    --letter-spacing: -0.01em;

    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size-xxlarge: #{math.div(40px, 16px) * 1rem};
        --font-size-large: #{math.div(24px, 16px) * 1rem};
        --font-size: 16px;
    }
}

@mixin font-serif($line-height: 0) {
    // font-family: var(--font-serif);
    // font-size: var(--font-size-medium);
    // font-weight: normal;
    // @if $line-height > 0 { line-height: $line-height; }
    // @else { line-height: var(--line-height-serif);  }
    @include font-sans();
}
@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-bold($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: 700;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-display($line-height: 0) {
    font-family: var(--font-display);
    font-weight: 700;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
