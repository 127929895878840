@use "sass:math";

:root {
  --y-header: 0;

  --padding-xxl: #{math.div(210px, 16px) * 1rem};
  --padding-xl: #{math.div(105px, 16px) * 1rem};
  --padding-l: #{math.div(60px, 16px) * 1rem};
  --padding-m: #{math.div(30px, 16px) * 1rem};
  --padding-s: #{math.div(20px, 16px) * 1rem};
  --padding-xs: #{math.div(15px, 16px) * 1rem};
  --padding-xxs: #{math.div(10px, 16px) * 1rem};
  --padding-xxxs: #{math.div(5px, 16px) * 1rem};

  --header-height: #{math.div(91px, 16px) * 1rem};
  
  --scrollbar-height: #{math.div(40px, 16px) * 1rem};
  
  --col1: #{math.div(390px, 16px) * 1rem};
  --col2: #{math.div(330px, 16px) * 1rem};
  --col3: 25%;
  --col4: 25%;
  
  --logo-width-big: #{math.div(816px, 16px) * 1rem};
  --logo-height-big: #{math.div(300px, 16px) * 1rem};
  --logo-width-small: #{math.div(660px, 16px) * 1rem};
  --logo-height-small: #{math.div(243px, 16px) * 1rem};
}

@media (max-width: $smartphone) {
  :root {
    --header-height: #{math.div(61px, 16px) * 1rem};
    --logo-width-big: 100%;
    --logo-height-big: auto;
    --logo-width-small: 100%;
    --logo-height-small: auto;
  }
}
