//↗ → ← ↖ ↧

@mixin link-icon() {
    @include basic-a();
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
        width: rem(11);
        height: rem(11);
        
        svg {
            display: block;
        }
    } 
}

.link-icon {
    @include link-icon();
}
