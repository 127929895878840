@mixin link-underline() {
    --origin: right;
    --size: 0;
    --padding-v: var(--padding-xxs);
    --padding-h: 0;
    --font-size: var(--font-size-base);
    --color: var(--primary-color);

    @include font-sans(.6667);
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    position: relative;

    @include basic-a();
    color: inherit;

    &.--light {
        --padding-h: 0 !important;
    }

    &::after {
        background-color: var(--color);

        content: "";
        position: absolute;
        inset: auto var(--padding-h) calc(var(--padding-v) * 0.6);
        
        display: block;
        height: 1px;
        
        scale: var(--size) 1;
        // transition: scale 0.3s ease;
        transform-origin: var(--origin);
    }

    @include isCursor() {
        &:hover {
            &::after {
                --size: 1;
                --origin: left;
            }
        }
    }

    &[aria-current="page"],
    &.--active {
        font-weight: 700;
        
        &.--light {
            font-weight: 400;
        }

        &::after {
            --size: 1;
        }
    }
}

.link-underline {
    @include link-underline();
}
