.widget-landing {
    --font-size-big: var(--font-size-large);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-m);
    --padding-h: 0;
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
    }
}

.widget-landing {
    @include font-sans();
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    
    .title {
        @include font-sans-bold();
        font-size: var(--font-size-big);
    }

    .text {
        @include font-serif();

        p:not(:last-child) {
            margin-bottom: var(--padding-xs);
        }
    }

    .column {
        ul > li {
            --origin: left;
            --size: 1;
            
            @include isCursor() {
                &:hover {
                    --size: 0;
                    --origin: right;

                    // a {
                    //     background: var(--primary-color);
                    //     color: var(--primary-bg);
                    // }
                }
            }

            position: relative;

            &:first-child {
                border-top: 1px solid currentColor;
            }
            
            a {
                @include font-sans(1);
                @include basic-a();
                color: var(--primary-color);
                // background: var(--primary-bg);
                display: flex;
                justify-content: space-between;
                align-content: center;
                padding: var(--padding-xxs) 0;

                span:last-child {
                    flex-grow: 1;
                    text-align: right;
                }

                &::after {
                    background-color: var(--primary-color);
    
                    content: "";
                    position: absolute;
                    inset: auto 0 0 0;
                    
                    display: block;
                    height: 1px;
                    
                    scale: var(--size) 1;
                    // transition: scale 0.3s ease;
                    transform-origin: var(--origin);
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        .column {
            &:not(:last-child) {
                margin-bottom: var(--padding-m);
            }
            
            ul > li {
                a {
                    border-bottom: 1px solid var(--primary-color);

                    &::after {
                        display: none;
                    }
                }
            }
        } 

        .title {
            margin-bottom: var(--padding-xs);
            
            &:not(:first-child) {
                margin-top: var(--padding-m);
            }
        }
    }

    @media (min-width: $smartphone) {
        > div {
            display: grid;
            grid-template-columns: var(--col1) var(--col2) var(--col3) var(--col4);
        }
        
        .column {
            padding: 0 var(--padding-m) var(--padding-xl);
            grid-column: span 1;
            position: relative;
            
            &:last-child {
                grid-column: span 2;
            } 
    
            &:not(:last-child) {
                &::after {
                    content: '';
                    height: 100%;
                    width: 1px;
                    background-color: var(--primary-color);
                    right: 0;
                    top: 0;
                    position: absolute;
                }
            }
        }

        .text {
            p:not(:last-child) {
                margin-bottom: rem(18);
            }
        }
    
        .title {
            margin-bottom: var(--padding-m);
            
            &:not(:first-child) {
                margin-top: var(--padding-m);
            }
        }
    }
}
