html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--primary-bg);

    > video {
        visibility: hidden;
        pointer-events: none;
    }
    
    main {
        width: 100%;
    }
    
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }

        // main {
        //     @include z-index($z-index-wrap);
        //     position: fixed;
        //     height: 100%;
        //     top: 0;
        //     left: 0;
        // }
    }
    
    &.__scroll-manual:not(.__noScroll) {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        
        &.__noScroll {
            overflow: hidden;
        }
    }
}

.icon {
    display: inline-block;

    svg {
        height: 100%;
        width: 100%;
    }
}

.line-parent {
    overflow: hidden;
}

*[aria-current="page"] {
    pointer-events: none;
    text-decoration: none;
}

button[disabled] {
    pointer-events: none;
    opacity: .3;
}

// Tweakpane
.tp-dfwv {
    @include z-index($z-index-tweakpane);
}

// #VideoBackground,
// #ImageBackground {
//     position: absolute;
//     left: 0;
//     top: 0;
//     height: 100vh;
//     width: 100vw;
//     pointer-events: none;
//     opacity: 0;
//     object-fit: cover;
// }
