@mixin button {
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --padding-v: var(--padding-xxxs);
    --padding-h: var(--padding-m);
    --font-size: var(--font-size-base);
    --icon: #{rem(20)};
    --size: 1;
    
    &.--l {
        --padding-v: var(--padding-xs);
        --font-size: var(--font-size-large);
    }

    &.--alt {
        --color: var(--primary-bg);
        --bg: var(--primary-color);
    }
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xxs);
    }

    @include basic-a();
    @include font-sans(.6667);
    font-size: var(--font-size);

    background-color: transparent;
    align-items: center;
    appearance: none;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    position: relative;
    text-align: center;
    vertical-align: top;
    padding: 0;

    // &::before,
    // &::after,
    // .icon::after,
    // .text::after,
    // .text::before {
    //     background-color: var(--color);
    //     content: "";
    //     position: absolute;
    //     display: block;
    //     transition: scale .1s ease;
    //     transition-delay: var(--delay);
    //     transform-origin: var(--origin);

    //     @include z-index(2);
    // }
    
    // TOP BOTTOM
    // &::after,
    // &::before {
    //     height: 1px;
    //     scale: var(--size) 1;
    // }

    // &::before {
    //     --origin: right;
    //     --delay: .3s;
    //     inset: 0 0 auto;
    // }
    
    // &::after {
    //     --origin: left;
    //     --delay: .1s;
    //     inset: auto 0 0;
    // }

    // LEFT RIGHT
    // .icon::after,
    // .text::after,
    // .text::before {
    //     width: 1px;
    //     scale: 1 var(--size);
    // }

    // .text::after {
    //     --origin: top;
    //     --delay: 0;
    //     inset: 0 auto 0 0;
    // }

    // .icon::after,
    // .text::before {
    //     --origin: bottom;
    //     --delay: .2s;
    //     inset: 0 -1px 0 auto;
    // }

    * { pointer-events: none; }
    span { display: block; }
    
    .bg {
        @include z-index(0);
        position: absolute;
        inset: 0 0 0 0;
        background: var(--bg);
        opacity: 0;
    }

    .text {
        @include z-index(1);

        border: 1px solid var(--color);
        color: var(--color);
        position: relative;
        flex-grow: 1;
        padding: var(--padding-v) var(--padding-h);

        border-radius: rem(12);
    }

    .icon {
        padding: var(--padding-xxxs);
        position: relative;
        border: 1px solid var(--color);
        border-left: 0;
        background: var(--color);

        // &::before {
        //     @include z-index(0);
        //     position: absolute;
        //     content: '';
        //     background-color: var(--color);
        //     inset: 0 0 0 0;
        //     transition: .2s ease-out;
        //     scale: 1 0;
        //     transform-origin: top;
        // }
        
        svg {
            @include z-index(1);
            position: relative;
            height: var(--icon);
            width: var(--icon);
            fill: var(--bg);
            // transition: .2s ease-out;
        }
    }

    @include isCursor() {
        &:hover {
            .text {
                background: var(--color);
                color: var(--bg);
            }

            // --size: 0;
            // --origin-h: left;
            // --origin-v: top;

            // &::before { --delay: 0; }

            // .icon::after,
            // .text::before { --delay: .1s; }

            // &::after { --delay: .2s; }

            // .text::after { --delay: .3s; }

            // .icon {
            //     &::before {
            //         scale: 1 1;
            //     }

            //     svg {
            //         fill: var(--bg);
            //         transition: .2s ease-out .2;
            //     }
            // }
        }
    }

    &[aria-current="page"],
    &.--active {
        .text {
            background: var(--color);
            color: var(--bg);
        }
    }

    @media (max-width: $smartphone) {
        .bg {
            opacity: 1;
        }
    }
}

.button {
    @include button;
}
