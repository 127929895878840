@use "sass:math";

.block-highlight {
    --font-size: var(--font-size-xxlarge);
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
    }
}

.block-highlight {
    padding: 0 var(--padding-h);
    background-color: var(--primary-bg);

    & + .bg {
        @include z-index($z-index-cookies);
        transform: scale(1.15);

        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;

        pointer-events: none;
        object-fit: contain;
        mix-blend-mode: difference;
    }

    &:first-child {
        padding-top: var(--header-height);
    }
    
    > div {
        border-bottom: 1px solid currentColor;    
        padding: var(--padding-v) 0;
    }
    
    h1 {
        @include font-display(.94444);
        font-size: var(--font-size);
        text-transform: uppercase;
    }
    
    @media (max-width: $smartphone) {
        &:first-child {
            padding-top: calc(var(--header-height) + var(--padding-m));
        }
        
        & + .bg {
            height: 100vw;
            width: 100vh;
            transform: rotate(90deg) scale(0.9);
            transform-origin: center;
            top: 23%;
            left: -40%;
        }
    }
}
