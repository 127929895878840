//↗ → ← ↖ ↧

@mixin link-arrow() {
    @include basic-a();
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: var(--font-size-base);

    &:before {
        content: '↗';
        margin-left: var(--padding-xxxs);
    }
    
    &.--download {
        &:before {
            content: '↧';
        }
    }
}

.link-arrow {
    @include link-arrow();
}
