.widget-title {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
    }
}

.widget-title {
    background-color: var(--primary-bg);

    &.--primary {
        padding-top: var(--header-height);
        
        > div {
            border-top: 0;
        }
    }

    &:not(.--primary) {
        > div {
            border-bottom-color: transparent;
        }
    }  
    
    .bg {
        @include z-index(1);

        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        pointer-events: none;
        object-fit: cover;
        mix-blend-mode: difference;
    }
    
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--padding-v) var(--padding-h);
        border-bottom: 1px solid currentColor;
        border-top: 1px solid currentColor;
        position: relative;
        
        svg {
            display: block;
            fill: var(--primary-color);
            width: var(--logo-width-big);
            height: var(--logo-height-big);
        }
    }

    h1 {
        width: 100%;
    }

    @media (max-width: $smartphone) {
        // &.--primary {
        //     padding-top: calc(var(--header-height) + var(--padding-m));
        // }

        > div {
            border-top: 0;
        }
        
        &:not(.--primary) {
            > div {
                padding-bottom: var(--padding-xs);
            }
        }

        h1 {
            width: 100%;
        }
    }
}
