.workshop-detail {
    --font-size-big: var(--font-size-large);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-m);
    --padding-h: 0;
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
    }
}

.workshop-detail {
    @include font-sans();
    font-size: var(--font-size);

    .title {
        @include font-sans-bold(.7666);
        font-size: var(--font-size-big);
        padding: var(--padding-s) 0;
        margin-bottom: 0;

        &:first-child {
            border-top: 1px solid currentColor;
        }
    }
    
    .info {
        @include font-serif();

        border-top: 1px solid currentColor;
        padding: var(--padding-s) 0;
    }
    
    figure {
        margin: var(--padding-m) 0 var(--padding-l);
    }

    .content {
        border-top: 1px solid currentColor;

        .header {
            border-bottom: 1px solid currentColor;
            padding: var(--padding-xxs) 0;
        }

        .text {
            padding: var(--padding-s) 0;
        }

        &:last-child {
            .text {
                padding-bottom: 0;
            }
        }

        ol {
            list-style: none;
            counter-reset: item;

            li {
                counter-increment: item;

                &:before {
                    content: counter(item) '.';
                    margin-right: rem(5);
                }
            }
        }
    }

    ul > li {
        max-width: rem(270);

        &:first-child {
            border-top: 0;
        }
    }

    @media (min-width: $smartphone) {
        figure {
            margin: 0 0 var(--padding-s) 0;
        }

        .info {
            padding: var(--padding-m) 0;
        }

        .content {
            .text {
                padding: var(--padding-m) 0;
            }
        }
    }
}
